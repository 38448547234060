import React from "react";
import InnerBanner from "views/Components/Comman/InnerBanner";
import { Link } from "react-router-dom";
import { Image } from "react-bootstrap";

import GoogleIcon from "assets/images/icons/google-icon.png";
import IosIcon from "assets/images/icons/ios-icon.png";

import Layout from "../Layout";

const signin = () => {
  return (
    <Layout>
      <InnerBanner SmallTitle="Login" Title="Login in to your <br /> account" />
      <section className="login-detail">
        <div className="white-box overlay-banner">
          <div className="buttons-auth">
            <Link to="/" className="btn outline-btn">
              <Image src={GoogleIcon} alt="Google" />
              Login with Google
            </Link>
            <Link to="/" className="btn outline-btn">
              <Image src={IosIcon} alt="Google" />
              Login with Apple
            </Link>
          </div>
        </div>
        <div className="login-signup-para">
          Don't have an account? <Link to="/signup">Sign Up</Link>
        </div>
      </section>
    </Layout>
  );
};

export default signin;
