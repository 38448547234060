import React from "react";
import { Link } from "react-router-dom";

import Header2 from "../Layout/Header2";
import Footer2 from "../Layout/Footer2";

const PageNotFoundPublic = () => {
  return (
    <React.Fragment>
      <div className="main-layout">
        <Header2 />

        <section className="launch-page pt-100">
          <div className="launch-meeting-section">
            <h2>Page not found!</h2>

            <Link
              to="/"
              className="btn btn-primary justify-content-center mt-5"
            >
              Home
            </Link>
          </div>
        </section>

        {/* <Footer2 /> */}
      </div>
    </React.Fragment>
  );
};

export default PageNotFoundPublic;
