import React from "react";

import Layout from "../Layout";
import InnerBanner from "views/Components/Comman/InnerBanner";
import { Container } from "react-bootstrap";

const TermsOfUse = () => {
  return (
    <Layout>
      <InnerBanner
        Title="Terms of Service"
        CustomClass="privacy-section-banner"
        SmallTitle="Last updated: November 24, 2023"
      />

      <section className="privacy-policy-section">
        <Container>
          <div className="privacy-policy-card">
            <ol>
              <li>
                <h2>Acceptance of Terms</h2>
                <p>
                  By using ZATAHUB, a Virtual Meeting Platform For Online
                  Conference Video, you agree to comply with these Terms of
                  Service.
                </p>
              </li>
              <li>
                <h2>Use of Services</h2>
                <ul>
                  <li>
                    You agree to use our services in accordance with all
                    applicable laws and regulations. You are responsible for
                    maintaining the confidentiality of your account information.
                  </li>
                </ul>
              </li>
              <li>
                <h2>User Conduct</h2>
                <p>You agree not to engage in any conduct that may:</p>
                <ul>
                  <li>Violate any laws or regulations</li>
                  <li>Infringe upon the rights of others</li>
                  <li>Disrupt the functionality or security of our services</li>
                </ul>
              </li>
              <li>
                <h2>Intellectual Property</h2>
                <p>
                  All content and materials available on our website are
                  protected by intellectual property laws. You may not use our
                  content without permission.
                </p>
              </li>
              <li>
                <h2>Limitation of Liability</h2>
                <p>
                  We are not liable for any damages or losses resulting from
                  your use of our services.
                </p>
              </li>
              <li>
                <h2>Termination</h2>
                <p>
                  We reserve the right to terminate or suspend your access to
                  our services at our discretion, without notice.
                </p>
              </li>
              <li>
                <h2>Governing Law</h2>
                <p>
                  These Terms of Service shall be governed by and construed in
                  accordance with the laws of <b>[Your Jurisdiction]</b>.
                </p>
              </li>
            </ol>
            <p>
              This content is a starting point and may need adjustments to fit
              the specific nature of your web app. It's highly recommended to
              consult with legal professionals to ensure compliance with
              relevant laws and regulations in your jurisdiction
            </p>
          </div>
        </Container>
      </section>
    </Layout>
  );
};

export default TermsOfUse;
