import React, { useState } from "react";
import InnerBanner from "views/Components/Comman/InnerBanner";
import { Container, Row, Col } from "react-bootstrap";
import { Link } from "react-router-dom";

import Layout from "../Layout";

const Pricing = () => {
  const [isMonthlyPlan, setIsMonthlyPlan] = useState(true);

  const togglePlan = () => {
    setIsMonthlyPlan((prevPlan) => !prevPlan);
  };
  return (
    <Layout>
      <InnerBanner
        CustomClass="pricing-banner"
        Title="Business, Financial, Technology <br /> and Healthcare"
        SmallTitle="Plans & Pricing for"
      />

      <section className="repeat-section pricing-plan-section">
        <Container>
          <div className="section-title text-center">
            <div className="small-title">Pricing</div>
            <h2>Pricing Plans</h2>
            <p>
              First 30 days absolutely free for any plan, no credit card
              required to get started.
            </p>
          </div>
          <div className="toggle-switch-btn">
            <span className={isMonthlyPlan ? "" : "active"}>Monthly</span>
            <label className="switch">
              <input
                type="checkbox"
                checked={isMonthlyPlan}
                onChange={togglePlan}
              />
              <span className="slider round"></span>
            </label>
            <span className={isMonthlyPlan ? "active" : ""}>Yearly</span>
          </div>
          <Row className="align-items-end">
            <Col md="6">
              <div className="plan-box basic-plan">
                <h3>Basic</h3>
                <p>Free</p>
                <Link to="/" className="btn outline-btn">
                  Sign up
                </Link>
                <ul>
                  <li>
                    <b>Meetings</b> Up to 2 hours per meeting
                  </li>
                  <li>
                    <b>10 meetings</b> Per week
                  </li>
                  <li>
                    <b>ChatGPT</b> to summary the meeting
                  </li>
                  <li>
                    <b>Team Chat</b>
                  </li>
                  <li>
                    <b>Mail & Calendar </b>
                  </li>
                  <li>
                    <b>Fast</b>
                  </li>
                  <li>
                    <b>Turn on caption</b>
                  </li>
                  <li>
                    <b>Setting background in video call</b>
                  </li>
                </ul>
              </div>
            </Col>
            <Col md="6">
              <div className="plan-box premium-plan">
                {isMonthlyPlan ? (
                  <p className="sale-para">
                    $10 savings monthly - SAVE UP TO 35%
                  </p>
                ) : (
                  <p className="sale-para"></p>
                )}
                <h3>Premium </h3>
                {isMonthlyPlan ? (
                  <p>
                    $228 <span> Yearly</span>
                  </p>
                ) : (
                  <p>
                    $29 <span> Monthly</span>
                  </p>
                )}
                <Link to="/" className="btn">
                  Buy Now
                </Link>
                <ul>
                  <li>
                    <b>Meetings</b> Unlimited hours
                  </li>
                  <li>
                    <b>Unlimited Meeting</b> Per week
                  </li>
                  <li>
                    <b>ChatGPT</b> to summary the meeting
                  </li>
                  <li>
                    <b>Team Chat</b>
                  </li>
                  <li>
                    <b>Mail & Calendar </b>
                  </li>
                  <li>
                    <b>Fast</b>
                  </li>
                  <li>
                    <b>Turn on caption</b>
                  </li>
                  <li>
                    <b>Setting background in video call</b>
                  </li>
                </ul>
              </div>
            </Col>
          </Row>
        </Container>
      </section>
    </Layout>
  );
};

export default Pricing;
