import React, { useEffect } from "react";
import { Routes, Route, useLocation } from "react-router-dom";
import "bootstrap/dist/css/bootstrap.min.css";
import "../src/assets/css/animate.min.css";
import "App.scss";
import "../src/assets/css/header.scss";
import "../src/assets/css/style.scss";

import PublicRoute from "./views/Routing/PublicRoute";
import HomePage from "./views/Client/Dashboard";
import PricingPage from "./views/Client/Pricing";
import ProfilePage from "./views/Client/Profile";
import SignInPage from "./views/Client/AuthSignIn";
import SignUpPage from "./views/Client/AuthSignUp";
import ScanPage from "./views/Client/Scan";
import DownloadPage from "./views/Client/Download";
import MeetingsPage from "./views/Client/Meetings";
import MeetingsDetailPage from "./views/Client/MeetingsDetail";
import AboutUsPage from "./views/Client/AboutUs";
import PrivacyPolicy from "views/Client/PrivacyPolicy/index";
import TermsOfUse from "views/Client/TermsOfUse/index";
import LaunchMeeting from "views/Client/LaunchMeeting/index";
import PageNotFoundPublic from "views/Client/PageNotFoundPublic/index";

const App = () => {
  const { pathname } = useLocation();
  useEffect(() => {
    window.scrollTo(0, 0);
  }, [pathname]);
  return (
    <div className="App">
      <Routes>
        <Route element={<PublicRoute />}>
          <Route path="/" element={<HomePage />} />
          <Route path="/pricing" element={<PricingPage />} />
          <Route path="/profile" element={<ProfilePage />} />
          <Route path="/signin" element={<SignInPage />} />
          <Route path="/signup" element={<SignUpPage />} />
          <Route path="/scan" element={<ScanPage />} />
          <Route path="/download" element={<DownloadPage />} />
          <Route path="/meetings" element={<MeetingsPage />} />
          <Route path="/meetings-detail" element={<MeetingsDetailPage />} />
          <Route path="/about-us" element={<AboutUsPage />} />
          <Route path="/privacy-policy" element={<PrivacyPolicy />} />
          <Route path="/terms-of-use" element={<TermsOfUse />} />
          <Route path="/j/:meeting_id" element={<LaunchMeeting />} />
          <Route path="/*" element={<PageNotFoundPublic />} />
        </Route>
      </Routes>
    </div>
  );
};

export default App;
